import React,{useEffect} from 'react'

const Button = (props) => {
    const {status,color} = props
    useEffect(() =>{

    },[props])
    return (
        <>
            <button style={{backgroundColor:color}} className={props.className} id={props.id} type="button" onClick={props.onClick}>{props.name}</button>
        </>
    )
}
export default Button;